import React from 'react';
import FormSection from './FormSection';
import InfoSection from './InfoSection';
import TopInfoSection from './TopInfoSection';
import IndexLayout from 'layouts';
import Header from 'components/Header';

const SellPage = () => {
  return (
    <IndexLayout>
      <Header />
      <TopInfoSection />
      <InfoSection />
      <FormSection />
    </IndexLayout>
  );
};

export default SellPage;
